<template>
  <v-main>

    <v-container
        id="users-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-account-multiple-outline"
          class="px-5 py-5"
      >
        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   dark
                   outlined
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-text>

          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-subtitle-2 member-name">Name</th>
                <th class="text-subtitle-2 member-phone-number">Phone #</th>
                <th class="text-subtitle-2 member-location">Location</th>
                <th class="text-subtitle-2 member-status">Status</th>
                <th class="text-subtitle-2 member-updated">Updated</th>
                <th class="text-subtitle-2 member-action">Action</th>

              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="user in users"
                  :key="user.id"
                  :class="user.status === 0 ? 'highlight-new-member' : ''"
              >
                <td>
                  <router-link :to="{ name: 'edit_member', params: {memberId: user.id} }">
                    {{ user.names }}
                  </router-link>
                </td>
                <td> {{ user.phoneNumber }} </td>
                <td> {{ user.city }}, {{ user.state }} </td>
                <td> {{ userStatusMap[user.status] }} </td>
                <td>
                  <span v-if="user.updated">{{ user.updated | formatDate }}</span>
                  <span v-else>{{ user.created | formatDate }}</span>
                </td>
                <td>
                  <router-link :to="{ name: 'edit_member', params: {memberId: user.id} }">
                    Review
                  </router-link>
                </td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'

export default {
  name: 'DashboardReviewMembersView',

  created() {
    this.fetchReviewMembers()
  },

  methods: {
    fetchReviewMembers() {
      api.fetchReviewMembers()
          .then(response => {
            this.users = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err, true)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      users: [],
      errors: [],
      userStatusMap: constants.getUserStatusMap()
    }
  }
}
</script>


<style lang="sass">
th.member-phone-number, th.member-updated, th.member-status, th.member-action
  width: 12%

tr.highlight-new-member
  background-color: #F1F8E9


</style>