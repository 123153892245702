
export default Object.freeze({
    PASSWORD_MIN_LENGTH: 8,
    MIN_BIRTH_YEAR: 1900,

    // User status
    USER_STATUS_NEW: 0,
    USER_STATUS_ACTIVE: 1,
    USER_STATUS_INACTIVE: 2,
    USER_STATUS_ADMIN_REVIEW: 3,
    USER_STATUS_REJECTED: 4,

    USER_ROLE_ADMIN: 'Admin',
    USER_ROLE_MEMBER: 'Member',
    USER_ROLE_FINANCE: 'Finance',
    USER_ROLE_SUPPORT: 'Support',

    // Dependent Status
    DEPENDENT_STATUS_PENDING: 0,
    DEPENDENT_STATUS_APPROVED: 1,
    DEPENDENT_STATUS_DIVORCED: 2,
    DEPENDENT_STATUS_ADMIN_REVIEW: 3,
    DEPENDENT_STATUS_DECEASED: 4,
    DEPENDENT_STATUS_REJECTED: 5,

    // Claim Status
    CLAIM_STATUS_PENDING: 0,
    CLAIM_STATUS_APPROVED: 1,
    CLAIM_STATUS_PAID: 2,
    CLAIM_STATUS_ADMIN_REVIEW: 3,
    CLAIM_STATUS_REJECTED: 4,

    // Invoice Status
    INVOICE_STATUS_ADMIN_REVIEW: 0,
    INVOICE_STATUS_OPEN: 1,
    INVOICE_STATUS_PAID: 2,

    // Payment Status
    PAYMENT_STATUS_ADMIN_REVIEW: 0,
    PAYMENT_STATUS_VERIFIED: 1,
    PAYMENT_STATUS_VOID: 2,
    PAYMENT_STATUS_REJECTED: 3,

    // Payment Methods
    PAYMENT_METHOD_BANK_DEPOSIT: 'Wells Fargo Bank Deposit',
    PAYMENT_METHOD_ZELLE: 'Zelle',
    PAYMENT_METHOD_BANK_TRANSFER_WF: 'Wells Fargo Bank Transfer',
    PAYMENT_METHOD_CHECK: 'Check',
    PAYMENT_METHOD_BANK_TRANSFER_NON_WF: 'Non-Wells Fargo Bank Transfer',

    // Invoice Types
    INVOICE_TYPE_REPLENISHMENT: 'Replenishment',
    INVOICE_TYPE_ANNUAL_FEE: 'Annual Fee',
    INVOICE_TYPE_REGISTRATION: 'Registration',

    getUserStatusMap() {
        return [
            'New',
            'Active',
            'Inactive',
            'Admin Review',
            'Rejected'
        ]
    },

    getDependentStatusMap() {
        return [
            'Pending',
            'Approved',
            'Divorced',
            'Admin Review',
            'Deceased',
            'Rejected'
        ]
    },

    getClaimStatusMap() {
        return [
            'Pending',
            'Approved',
            'Paid',
            'Admin Review',
            'Rejected'
        ]
    },

    getInvoiceStatusMap() {
        return [
            'Review',
            'Open',
            'Paid',
        ]
    },

    getPaymentStatusMap() {
        return [
            'Admin Review',
            'Verified',
            'Void',
            'Rejected'
        ]
    },

    getPaymentMethods() {
        return [
            this.PAYMENT_METHOD_BANK_TRANSFER_WF,
            this.PAYMENT_METHOD_BANK_DEPOSIT,
            this.PAYMENT_METHOD_ZELLE,
            this.PAYMENT_METHOD_CHECK,
            this.PAYMENT_METHOD_BANK_TRANSFER_NON_WF
        ]
    },

    getInvoiceTypes() {
        return [
            this.INVOICE_TYPE_REPLENISHMENT,
            this.INVOICE_TYPE_ANNUAL_FEE,
            this.INVOICE_TYPE_REGISTRATION
        ]
    },

    getUserRoles() {
        return {
            [this.USER_ROLE_ADMIN]: 'ROLE_ADMIN',
            [this.USER_ROLE_MEMBER]: 'ROLE_MEMBER',
            [this.USER_ROLE_FINANCE]: 'ROLE_FINANCE',
            [this.USER_ROLE_SUPPORT]: 'ROLE_SUPPORT'
        }
    }
})
